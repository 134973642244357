<template>
  <div class="wameed-dashboard-page-content">
    <page-header
      :title="$t('settings.add_admin_details_title')"
      :sub-title="$t('settings.add_admin_details_subTitle')"
    />
    <div class="vue-form-wizard mb-3 md">
      <div class="wizard-tab-content">
        <!-- step 1 tab -->
        <div id="step-1" class="wizard-tab-container">
          <validation-observer
            ref="stepOneForm"
            #default="{ invalid }"
            tag="form"
            style="overflow: visible"
          >
            <b-row>
              <b-col cols="12">
                <h5 class="tab-title">
                  {{ $t('users_details.account_details') }}
                </h5>
              </b-col>
              <b-col md="6">
                <text-input
                  id="name"
                  v-model="submitData.name"
                  :label="$t('users_data.name')"
                  :placeholder="$t('users_data.name_placeholder')"
                  input-classes="text-bold-14 text-font-main"
                  name="name"
                  rules="required"
                />
              </b-col>
              <b-col md="6">
                <wameed-dropdown
                  v-if="getCountries"
                  id="country"
                  v-model="submitData.country"
                  :label="$t('users_data.country')"
                  :placeholder="$t('users_data.country_placeholder')"
                  classes="text-bold-14 w-100"
                  value-classes="text-med-14 text-font-secondary"
                  title="name"
                  variant="disable"
                  :items="getCountries"
                  :search="true"
                  text="name"
                  name="country"
                  rules="required"
                  @input="changeCities()"
                />
              </b-col>
              <b-col md="6">
                <text-input
                  id="email"
                  v-model="submitData.email"
                  :label="$t('users_data.email')"
                  :placeholder="$t('users_data.email_placeholder')"
                  input-classes="text-bold-14 text-font-main"
                  name="email"
                  rules="required"
                />
              </b-col>
              <b-col md="6">
                <wameed-dropdown
                  id="city"
                  v-model="submitData.city"
                  :label="$t('users_data.city')"
                  :placeholder="$t('users_data.city_placeholder')"
                  classes="text-bold-14 w-100"
                  value-classes="text-med-14 text-font-secondary"
                  title="name"
                  variant="disable"
                  :items="getCitiesItems"
                  :search="true"
                  text="name"
                  name="city"
                  rules="required"
                />
              </b-col>
              <b-col md="6">
                <text-input
                  id="new-password"
                  v-model="submitData.password"
                  :label="$t('users_data.password')"
                  :placeholder="$t('users_data.password_placeholder')"
                  field-classes="mb-5"
                  input-classes="text-bold-14 text-font-main"
                  name="password"
                  rules="required|password"
                  is-append
                  :icon="showNewPassword ? 'eyeon-icon' : 'eyeoff-icon'"
                  :type="showNewPassword ? 'password' : 'text'"
                  @iconClick="showNewPassword = !showNewPassword"
                />
              </b-col>
              <b-col md="6">
                <div
                  id="__BVID__73"
                  role="group"
                  class="form-group wameed-input text-reg-14 text-font-main"
                >
                  <div>
                    <phone-number-input
                      v-model="submitData.phone"
                      :label="$t('users_data.phone')"
                      input-classes="text-bold-14 text-font-main"
                      name="phone"
                      rules="required|length:9"
                      @update="results = $event"
                    />
                  </div>
                </div>
              </b-col>
              <b-col md="6">
                <text-input
                  id="confirm-new-password"
                  v-model="submitData.password_confirmation"
                  :label="$t('users_data.password_confirmation')"
                  :placeholder="
                    $t('users_data.password_confirmation_placeholder')
                  "
                  field-classes="mb-5"
                  input-classes="text-bold-14 text-font-main"
                  name="password_confirmation"
                  rules="required|confirmed:password"
                  is-append
                  :icon="'eyeon-icon'"
                  :type="showConfirmPassword ? 'password' : 'text'"
                  @iconClick="showConfirmPassword = !showConfirmPassword"
                />
              </b-col>
              <b-col md="6">
                <wameed-dropdown
                  v-if="getDashboardDetails"
                  id="role"
                  v-model="role"
                  :label="$t('users_data.roles')"
                  :placeholder="$t('users_data.roles_placeholder')"
                  classes="text-bold-14 w-100"
                  value-classes="text-med-14 text-font-secondary"
                  title="name"
                  translate="roles."
                  variant="disable"
                  :items="getDashboardDetails.roles"
                  text="name"
                  name="role"
                  rules="required"
                  :multiple="false"
                />
              </b-col>
              <b-col md="6">
                <wameed-dropdown
                  v-if="permissionsList"
                  id="permissions"
                  v-model="submitData.permissions"
                  :disabled="submitData.role ? false : true"
                  :label="$t('users_data.permissions')"
                  :placeholder="$t('users_data.permissions_placeholder')"
                  classes="text-bold-14 w-100"
                  value-classes="text-med-14 text-font-secondary"
                  title="name"
                  translate="permissions."
                  variant="disable"
                  :items="permissionsList"
                  text="name"
                  name="permissions"
                  rules="required"
                  :multiple="true"
                  position="top"
                />
              </b-col>
            </b-row>
            <hr />
            <b-row class="">
              <b-col sm="12" class="package-details-name pt-3 d-flex">
                <wameed-btn
                  classes="text-med-14 text-white rounded-10 mx-5"
                  :title="$t('orders_page.add_on')"
                  type="button"
                  variant="main"
                  @submitAction="submitAdmin"
                />

                <wameed-btn
                  classes="ml-auto mr-5 text-med-14 text-font-secondary rounded-10 "
                  :title="$t('common.cancel')"
                  type="button"
                  variant="gray"
                  @submitAction="cancelOrder"
                />
              </b-col>
            </b-row>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import { mapActions, mapGetters } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import WameedBtn from '@/components/WameedBtn.vue';
import TextInput from '@/components/TextInput.vue';
import WameedDropdown from '@/components/WameedDropdown.vue';
import PackageContent from '@/components/pages/applicants/PackageContent.vue';
import MultiImagesUpload from '@/components/multiImagesUpload.vue';
import TabContent from '@/components/vue-form-wizard/TabContent.vue';
import '@/assets/scss/wizard.scss';
// import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import PhoneNumberInput from '@/components/PhoneNumberInput.vue';

import PolicyModal from '@/components/pages/applicants/PolicyModal.vue';
import Calender from '@/components/Calender.vue';
import TextArea from '@/components/TextArea.vue';
import CustomStep from '@/components/pages/applicants/CustomStep.vue';
import SetpsWizard from '@/components/steps-wizard/WizardNumber.vue';

export default {
  components: {
    PageHeader,
    WameedBtn,
    WameedDropdown,
    TextInput,
    MultiImagesUpload,
    PackageContent,
    ValidationProvider,
    ValidationObserver,
    TabContent,
    PolicyModal,
    Calender,
    TextArea,
    CustomStep,
    SetpsWizard,
    PhoneNumberInput,
  },
  data() {
    return {
      dateRange: [],
      countries: [
        'المملكة العربية السعودية',
        'اليمن',
        'الكويت',
        'مصر',
        'سوريا',
      ],
      cities: ['صنعاء', 'تعز', 'إب', 'عدن'],
      showNewPassword: true,
      showConfirmPassword: true,
      results: null,
      permissionsList: null,
      role: null,
      getCitiesItems: [],
      submitData: {
        name: null,
        country: null,
        email: null,
        city: null,
        password: null,
        phone: null,
        password_confirmation: null,
        role: null,
        permissions: [],
      },
    };
  },

  computed: {
    ...mapGetters({
      getDashboardDetails: 'getDashboardDetails',
      getCountries: 'getCountries',
      getCities: 'getCities',
    }),
  },
  watch: {
    role(data) {
      this.submitData.permissions = null;
      this.submitData.role = data;
      this.permissionsList = data.permissions;
    },
    getCities(data) {
      this.getCitiesItems = data;
    },
  },
  created() {
    this.loadCountries();
    this.loadDashboardDetails();
  },
  mounted() {},
  methods: {
    ...mapActions({
      addAdministrators: 'addAdministrators',
      loadDashboardDetails: 'loadDashboardDetails',
      loadCountries: 'loadCountries',
      loadCities: 'loadCities',
    }),
    submitAdmin() {
      this.addAdministrators({
        name: this.submitData.name,
        country: this.submitData.country,
        email: this.submitData.email,
        city: this.submitData.city,
        password: this.submitData.password,
        phone: this.submitData.phone,
        password_confirmation: this.submitData.password_confirmation,
        role: this.submitData.role.name,
        permissions: this.submitData.permissions,
      }).then(() => {
        this.$router.push({
          name: 'administrators',
          params: { lang: this.$i18n.locale },
        });
      });
    },
    cancelOrder() {
      this.$router.push({
        name: 'administrators',
        params: { lang: this.$i18n.locale },
      });
    },
    changeCities() {
      this.submitData.city = null;
      if (this.submitData.country) {
        this.loadCities(this.submitData.country.id);
      }
    },
  },
};
</script>
